const addToCartButtonElements = document.querySelectorAll('.js-show-add-to-cart-modal');
addToCartButtonElements.forEach(addToCartButtonElement => {

    function importProductModalsWith() {
        import("./addToCartModal/productModals.js" + "?v=" + Math.floor(Date.now() / 100000))
            .then((module) => {
                module.productModals(addToCartButtonElement);
            })
            .catch((err) => {
                console.error('ERROR:', err);
            });
    }

    addToCartButtonElement.addEventListener('click', importProductModalsWith);
});
